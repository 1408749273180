module.exports = [
  ".Net",
  "3D Max",
  "3D Maya",
  "4Strategy",
  "Abap Consultant",
  "Abs",
  "Account Management",
  "Accounting",
  "Accounts",
  "Actriz",
  "Ad",
  "Adaptability",
  "Admininstrative Management",
  "Ado.Net",
  "Adobe",
  "Adobe After Effects",
  "Adobe Analytics",
  "Adobe Creative Cloud",
  "Adobe Illustrator",
  "Adobe Indesign",
  "Adobe Photoshop",
  "Adobe Premiere Pro",
  "Adobe Tag Manager",
  "Adrministracion Personal",
  "Advertising",
  "After Effect",
  "After Effects",
  "Agile Methodologies",
  "Agile Software Development",
  "Agrobusiness",
  "Ajax",
  "Algorithms",
  "Analysis",
  "Analytic English (A*)",
  "Analytics",
  "Android",
  "Android App Development",
  "Android Development",
  "Angular",
  "Angular 5",
  "Angularjs",
  "Animation",
  "Apache",
  "Apache Server",
  "Apache Spark",
  "Apache Hadoop",
  "Application Architecture",
  "Application Implementation",
  "Apps",
  "Arbitration Laws",
  "Architectural Design",
  "Architecture",
  "Arduino Microcontroller",
  "Article Writing",
  "Asp",
  "Asp.Net",
  "Assembly Language Programming",
  "Atl",
  "Auditing",
  "Auto Cad",
  "Autocad",
  "Autodesk Inventor",
  "Automation",
  "Automotive",
  "AWS",
  "Badminton Coach",
  "Banner Designing",
  "Bdm",
  "BI",
  "Big Data",
  "Biocides",
  "Bioinformatics",
  "Biological Databases",
  "Blogging",
  "Bootstrap",
  "Brand Curation",
  "Brand Development",
  "Brand Management",
  "Brand Promotion",
  "Brand Strategy",
  "Branding",
  "Business Analysis",
  "Business Analytics",
  "Business Analyst",
  "Business Consulting",
  "Business Continuity",
  "Business Development",
  "Business Intelligence",
  "Business Management",
  "Business Planning",
  "Business Strategy",
  "Business Studies",
  "C",
  "C Programming",
  "C++",
  "CAD",
  "Catia",
  "CCNA",
  "Cisco Packet Tracer",
  "Client Follow Up",
  "Client Servicing",
  "Cloud Computing",
  "Codeigniter",
  "Coding",
  "Communication",
  "Communication Skills",
  "Comprehensive Meta-Analysis (CMA)",
  "Computer Application",
  "Computer Vision",
  "Consultancy",
  "Consulting",
  "Content Creation",
  "Content Editing",
  "Content Marketing",
  "Content Writing",
  "Content Writing & Marketing",
  "Copywriting",
  "Core Java",
  "Core Php",
  "Coreldraw",
  "Corporate Communication",
  "Country Coordination",
  "Creating Web Page",
  "Creative Thinker",
  "Creative Writing",
  "Creativity",
  "Credit",
  "Crisis Management",
  "CRM",
  "Cryptography",
  "CSS",
  "CSS3",
  "Cultural Exchange",
  "Curriculum Designer",
  "Customer Analytics",
  "Customer Service",
  "Dancing",
  "Data Analysis",
  "Data Analytics",
  "Data Base",
  "Blockchain",
  "Data Mining",
  "Data Science",
  "Data Security",
  "Data Structures",
  "Data Warehousing",
  "Database",
  "Database Management Systems",
  "DBMS",
  "DBMS - Mysql",
  "DBMS- Mysql",
  "Debit",
  "Decision Making",
  "Deep Learning",
  "Delivery Management",
  "Natural Language Processing",
  "NLP",
  "Figma",
  "Design Of Machine Foundation",
  "Detail-Oriented.",
  "Develop Mobile Web Apps",
  "Developer",
  "Digital Advertising",
  "Digital Marketing",
  "Digital Strategy",
  "Digital Testing",
  "Django",
  "Docker",
  "Drafting Contracts",
  "Dream Weaver CS6",
  "Dreamweaver",
  "Drupal",
  "Dynatrace",
  "Editing",
  "Education Management",
  "E-Filing",
  "Electrical Installation",
  "Electrical Project Engineer",
  "Emberjs",
  "Employer Branding",
  "EMV",
  "Engineering",
  "Entity Framework",
  "Entrepreneurship",
  "Environmental Geotechnology",
  "Equity Research",
  "Ergsd",
  "ERP",
  "ES6",
  "Essay Writing",
  "ETL Testing",
  "Event Management",
  "Event Organising",
  "Event Organization",
  "Event Promotion",
  "Excel",
  "Excellent Communication And Organizational Skills.",
  "Express",
  "Expressjs",
  "Facebook",
  "Facebook Insights",
  "Facilitator",
  "Fast Learner",
  "Feathersjs",
  "Fighting",
  "Film Editing",
  "Finance",
  "Financial Management",
  "Financial Planning",
  "Financial Research",
  "Finite Element (Plaxis 3D)",
  "Firebase",
  "Fitness Coach",
  "Flash",
  "Flask",
  "Flutter",
  "Flexible",
  "Force Control",
  "Foreign Languages",
  "Foundary",
  "Foundation Design",
  "Frontend Developement",
  "Game Development",
  "Gaming",
  "Genesis Framework",
  "Genetics",
  "Genomics",
  "Get Boarded Testing",
  "Git",
  "Good Communication",
  "Good Listener",
  "Good Negotiator",
  "Google Analytics",
  "Google Tag Manager",
  "Google Web Analytics",
  "Google Cloud Platform",
  "Gradle",
  "Graphics",
  "Guitarist",
  "Hadoop",
  "Handball Coach",
  "Handling Pressure",
  "Hard Working",
  "Hardware",
  "Hardware And Networking",
  "Hootsuite",
  "Hp Load Runner",
  "Hp Network Virtualization (Shunra)",
  "Hp Performance Center",
  "Hp Quality Centre / Alm",
  "Hp Sitescope",
  "HR Analytics",
  "HR Management",
  "HTML",
  "HTML 5",
  "HTML And CSS",
  "HTML5",
  "PowerBI",
  "Human Resources",
  "Cybersecurity",
  "IIS Server",
  "Illustrator",
  "Induction",
  "Informatica",
  "Information Gathering And Management.",
  "Information Security",
  "Inkscape",
  "Installments",
  "Instrumentation-Sr",
  "Integration",
  "Intellectual Property",
  "Interest In Digital Advertising",
  "Interior Design",
  "International Relations",
  "Internet Marketing",
  "Inventory Management",
  "Investment Analysis And Portfolio Management",
  "Ionic",
  "IOS",
  "IOS And Android",
  "IOS Development",
  "IPO Grading",
  "IT",
  "ITIL",
  "J2EE",
  "Java",
  "Java (Android)",
  "Java Swing",
  "Java.Sql",
  "Javafx",
  "Javascript",
  "Jira",
  "Jmeter",
  "Joomla",
  "Jprofiler",
  "Jquery",
  "Jquery, Json And Javascript",
  "JS",
  "Json",
  "Keras",
  "Kanban",
  "KPI Reporting",
  "Kali Linux",
  "Kotlin",
  "Kubernetes",
  "Lamp",
  "Language Expert",
  "Laravel",
  "Law",
  "Leadership",
  "Legal",
  "Legal Documents Preparation",
  "Legal Drafting",
  "Legal Research",
  "Legal Writing",
  "Lifesaving",
  "Linux",
  "Linux Servers",
  "Linux Servers (Hosting)",
  "Literary Criticism",
  "Logic Design",
  "Logical Thinking",
  "Logo Designer",
  "Logo Designing",
  "Low Voltage Designer",
  "Mac",
  "Mac OS X",
  "Machine Learning",
  "Mad Executive",
  "Maintenance",
  "Maintenance Management",
  "Management",
  "Managing Skills",
  "Managing Workforce",
  "Manual Test",
  "Manufacturing",
  "Market Research",
  "Market Strategy",
  "Marketing",
  "Marketing Communication",
  "Marketing Online",
  "Marketing Strategy",
  "Martial Arts",
  "Material Managment(Sap)",
  "Mathematics",
  "MBA HR",
  "MCSE",
  "MEAN",
  "MEAN Stack",
  "Mechanical",
  "Media And Public Relations",
  "Media Laws",
  "Mediator",
  "Micro Processor",
  "Microchips",
  "Microcontroller",
  "Microproccesor",
  "Microprocessor",
  "Microprocessor Programming",
  "Microsoft Azure",
  "Microsoft Excel",
  "Microsoft Excel Specialist",
  "Microsoft Expert",
  "Microsoft Office",
  "Microsoft Office: Microsoft Word",
  "Microsoft Powerpoint",
  "Mircrosoft Office",
  "Mobile Accessories",
  "Mobile Marketing",
  "Mongodb",
  "Motion Graphics Designer",
  "Ms Excel",
  "Ms Office",
  "Ms Powerpoint",
  "Ms Sql",
  "Ms Word",
  "Ms Access",
  "Multitasking",
  "MVC",
  "Level Design",
  "Mysql",
  "Naves",
  "Negotiation",
  "Negotiations",
  "Networking",
  "Nextjs",
  "NFC",
  "Node Js",
  "Nodejs",
  "Node-Red",
  "Nosql",
  "Note Taking",
  "Objective -C",
  "Scikit-Learn",
  "Pandas",
  "Data Visualization",
  "Pytorch",
  "Tensorflow",
  "On Boarding",
  "Online Lead Generation",
  "Online Marketing",
  "On-Line Marketing",
  "OOPS",
  "Opencv",
  "Operations",
  "Oracle",
  "Oracle Database",
  "Organising",
  "Organizational Design",
  "Organizer",
  "Outdoor Marketing",
  "Paintings",
  "Payment Processing Softwares",
  "People Management",
  "Performance Management",
  "Performance Testing",
  "Perl",
  "Personal Branding",
  "Photography",
  "Photoshop",
  "Photoshop CS6",
  "Php",
  "Power BI",
  "Tableau",
  "IOT",
  "Php/Mysql",
  "Pilot",
  "PL/SQL",
  "Planning",
  "PLC",
  "PMP",
  "Policy Analysis",
  "Political Sciences",
  "Positive Attitude",
  "Postgresql",
  "Powerpoint",
  "Pr",
  "Practical Thinking",
  "Presentation Skills",
  "Problem Solving",
  "Process Optimisation",
  "Product Development",
  "Product Manager",
  "Proficiency In Excel",
  "Microsoft Word",
  "Proggramming",
  "Program Management",
  "Programmer",
  "Programming",
  "Programming - C++",
  "Programming C",
  "Programming In C",
  "Programming In C++ And Basic Java",
  "Project Management",
  "Project Planning",
  "Public Relations",
  "Public Speaker",
  "Public Speaking",
  "Pulmonary Diseases",
  "Pulmonologist",
  "Python",
  "Python/Django",
  "QA",
  "QT",
  "Qtp / Uft",
  "R",
  "R Language",
  "R Programming",
  "Racer",
  "React",
  "React Native",
  "React.Js",
  "Real Estate Consulting",
  "Reasearch",
  "Recruitment",
  "Requirement Analysis",
  "Requirement Gathering",
  "Research",
  "Research And Analysis",
  "Research/ Translation/ Interpretation",
  "Ux Design",
  "Researcher - Ux Designs",
  "Responsable",
  "Responsive Web Designer",
  "Rest Api",
  "Restful Api",
  "Revenue Analysis",
  "Revit",
  "Rewards",
  "Robotics",
  "Rock Mechanics",
  "Ruby",
  "Ruby On Rails",
  "Sad",
  "Sads",
  "Sales",
  "Sales Representative",
  "SAP",
  "SAP 2000",
  "SAS",
  "SASS",
  "SCCM",
  "Science And Technology",
  "Scrum",
  "Scrum Master",
  "Search Engine Optimization",
  "Security Governance",
  "Selenium",
  "Self-Confidence",
  "SEM Expert",
  "Senior SEO Executive",
  "SEO",
  "SEO Expert",
  "SEO Professional",
  "Sequence Analysis",
  "Service Delivery",
  "Shift Incharge",
  "Singing",
  "Sketching",
  "Slack",
  "SMO",
  "Smooth Operator",
  "Social",
  "Social Media",
  "Social Media Hiring",
  "Social Media Management",
  "Social Media Marketing",
  "Social Media Strategy",
  "Social Networking",
  "Social Networks",
  "Software",
  "Software Development",
  "Software Testing",
  "Soil Dynamics",
  "Soil Improvement Techniques",
  "Soil Mechanics",
  "Solution Design",
  "Sports",
  "SPSS",
  "SQL",
  "SQL Server",
  "STAAD.Pro",
  "Statistics",
  "Probability",
  "Standup Comedy",
  "Startup Mentoring",
  "Startups",
  "Statistical Programming Using SAS",
  "Statutory Compliance",
  "Strategic Planning",
  "Strategic Thinking",
  "Strategy",
  "Strategy Planning",
  "Stress Management",
  "Sustainable Design",
  "Swift",
  "Tag Manager",
  "Talent Acquisition",
  "Talent Branding",
  "Talent Management",
  "Talent Strategy",
  "Tally",
  "Tally-9 Erp",
  "Teacher",
  "Teaching",
  "Team Building",
  "Team Lead",
  "Team Leadership",
  "Team Player",
  "Teamwork",
  "Teamworker",
  "Technical Account Management",
  "Technical Analysis",
  "Technical And Content Writing",
  "Technical Skills",
  "Technical Support",
  "Technology",
  "Telecalling",
  "Testing",
  "Testing Games",
  "Thirdlight",
  "Time Management",
  "Training",
  "Training And Coaching",
  "Training And Development",
  "Transcriptomics-Microarray",
  "Translator",
  "UI Design",
  "UML",
  "Unix",
  "Urban Design",
  "Usability",
  "User Experience / UX",
  "User Interface / UI",
  "UX",
  "Valuation Reports",
  "Vendor And Client Negotiation",
  "Vfx Artist",
  "Video Editing",
  "Video Making",
  "Video Production",
  "Videography",
  "Visual Basic",
  "Matlab",
  "Visual Studio",
  "Visualization",
  "Vuejs",
  "Vue.Js",
  "Web Analytics",
  "Web Design",
  "Web Designer",
  "Web Designing",
  "Web Development",
  "Web Programming",
  "Web Services",
  "Web Technologies",
  "Webpack Builder",
  "Website Development",
  "Windows 7",
  "Windows 7,8 & 10",
  "Word",
  "Wordpress",
  "Wp Rest Api V2",
  "Writing",
  "Writing And Public Speaking",
  "Written And Oral Advocacy",
  "XHTML",
  "XML",
];
